import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translateOrderState',
})
export class TranslateFolderOrderStatePipe implements PipeTransform {
    transform(State: string): string {
        switch (State) {

            case 'EK_FOLDER_CREATED':
                return '1. En cours de création';

            case 'EK_FOLDER_IN_PROGRESS':
                return '2. En cours de traitement Ekiclik';

            case 'EK_FOLDER_RESERVE':
                return '3. Réservé Ekiclik';

            case 'EK_FOLDER_REFUSED':
                return '4. Dossier Rejeté';

            case 'CENTRAL_RISK_CONSULTATION':
                return '5. Consultation de la Centrale des Risques';

            case 'CLIENT_CENTRAL_RISK_NOTIFICATION':
                return '6. Notification Client Centrale des risques';

            case 'EK_BANK_IN_PROGRESS':
                return '7. Pré-notification banque';

            case 'BANK_NOTIFICATION':
                return '8. Notification banque (Attente accord)';

            case 'EK_BANK_RESERVE':
                return '9. Dossier réserve banque';

            case 'EK_BANK_REFUSED':
                return '10. Dossier Rejeté Banque';

            case 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING':
                return '11. Control avant impression des garanties';

            case 'EK_GUARTENTEES_PENDING_SIGNATURE':
                return '12. Garanties en cours de signature';

            case 'EK_GUARTENTEES_SIGNED_IN_PROGRESS':
                return '13. Garanties signés en cours validation Ekiclik';

            case 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS':
                return '14. Garanties signés en cours validation Banque';

            case 'BANK_PAYMENT_CONFIRMATION':
                return '15. Confirmation Paiement Banque';

            case 'FINANCIAL_FOLDER':
                return '16. Dossiers financés';

            case 'EK_PHYSICAL_FOLDER_IN_DELIVERY':
                return '17. Dossier physique en cour de livraison';

            case 'EK_PHYSICAL_FOLDER_IN_PROGRESS':
                return '18. Dossier physique en traitement Ekiclik';

            case 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY':
                return '19. Dossier physique en cours d\'envoie banque';

            case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                return '20. Control dossier physique banque';

            case 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED':
                return '21. Dossier physique accepté banque';

            case 'EK_PHYSICAL_FOLDER_PENDING':
                return '22. Dossier physique en préparation';

            case 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK':
                return '23. Dossier physique en attente de recupération';

            case 'EK_WAITING_FOR_CLIENT':
                return '24. Dossier physique en attente du client';

            case 'EK_CLIENT_NO_RESPONSE':
                return '25. Client ne reponds pas';

            case 'EK_PHYSICAL_FOLDER_PICKEDUP':
                return '26. Dossier physique ramassé';

            case 'EK_PHYSICAL_FOLDER_DELIVERED':
                return '27. Dossier physique livré';

            case 'EK_FOLDER_PICKUP_FAILED':
                return '28. Echec de recuperation du dossier physique';

            case 'EK_BANK_PHYSICAL_FOLDER_RESERVE':
                return '29. Dossier physique réserve banque';

            case 'EK_BANK_PHYSICAL_FOLDER_REFUSED':
                return '30. Dossier physique rejeté banque';

            case 'EK_CLIENT_FOLDER_CANCELED':
                return '31. Dossier annulé (CLIENT)';

            case 'EK_ADMIN_FOLDER_CANCELED':
                return '32. Dossier annulé (ADMIN)';

            case 'EK_ORDER_CREATED':
                return 'Commande créée';

            case 'EK_ORDER_PENDING':
                return 'Commande en attente';

            case 'EK_ORDER_IN_PREPARATION':
                return 'Commande en préparation';

            case 'EK_ORDER_IN_DELIVERY':
                return 'Livraison en cours';

            case 'EK_ORDER_DELIVERED':
                return 'Client livré';

            case 'EK_CLIENT_ORDER_CANCELED':
                return 'Commande annulée';

            default:
                return State;
        }
    }
}
