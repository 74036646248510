import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ADMIN_CONFIG} from "../../../../environments/environment";
import { saveAs } from 'file-saver';






@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  url: string;



  constructor(private http : HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}clients`;
  }



  downloadExcel(folderState: string | null) : void{
    const params = folderState ? {folderState} :{};
    const apiUrl = `${this.url}/get-client-excel`;
     this.http.get(apiUrl, {
      params,
      responseType: 'arraybuffer'
    }).subscribe(response => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       const translatedState = this.translateFolderState(folderState);
       const fileName = folderState ? `EkiClik clients ${translatedState}.xlsx`.replace(/[\s\/]/g, '_') : 'EkiClik clients.xlsx';

       saveAs(blob, fileName);
    });
  }

  private translateFolderState(folderState: string): string {
    switch (folderState) {
      case 'EK_FOLDER_CREATED':
        return 'En cours de création';
      case 'EK_FOLDER_IN_PROGRESS':
        return 'En cours de traitement Ekiclik';
      case 'EK_FOLDER_RESERVE':
        return 'Dossier Réservé Ekiclik';
      case 'EK_BANK_IN_PROGRESS':
        return 'Pré-notification banque';
      case 'EK_GUARTENTEES_PENDING_SIGNATURE':
        return 'Garanties en cours de signature';
      case 'EK_GUARTENTEES_SIGNED_IN_PROGRESS':
        return 'Garanties signés en cours validation Ekiclik';
      case 'EK_PHYSICAL_FOLDER_PENDING':
        return 'Dossier physique en préparation';
      case 'EK_PHYSICAL_FOLDER_WAITING_FOR_PICK':
        return 'Dossier physique en attente de recupération';
      case 'EK_WAITING_FOR_CLIENT':
        return 'Dossier physique en attente du client';
      case 'EK_CLIENT_NO_RESPONSE':
        return 'Client ne reponds pas';
      case 'EK_PHYSICAL_FOLDER_PICKEDUP':
        return 'Dossier physique ramassé';
      case 'EK_PHYSICAL_FOLDER_IN_DELIVERY':
        return 'Dossier physique en cour de livraison';
      case 'EK_PHYSICAL_FOLDER_DELEVERED':
        return 'Dossier physique livré';
      case 'EK_FOLDER_PICKUP_FAILED':
        return 'Echec de recuperation du dossier physique';
      case 'EK_PHYSICAL_FOLDER_IN_PROGRESS':
        return 'Dossier physique en traitement Ekiclik';
      case 'EK_BANK_PHYSICAL_FOLDER_IN_DELIVERY':
        return 'Dossier physique en cours d\'envoie banque';
      case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
        return 'Control dossier physique banque';
      case 'EK_BANK_PHYSICAL_FOLDER_ACCEPTED':
        return 'Dossier physique accepté banque';
      case 'EK_BANK_PHYSICAL_FOLDER_RESERVE':
        return 'Dossier physique réserve banque';
      case 'EK_BANK_PHYSICAL_FOLDER_REFUSED':
        return 'Dossier physique rejeté banque';
      case 'EK_PHYSICAL_FOLDER_REFUSED':
        return 'Dossier physique Rejeté';
      case 'EK_ADMIN_FOLDER_CANCELED':
        return 'Dossier annulé (ADMIN)';
      case 'EK_CLIENT_FOLDER_CANCELED':
        return 'Dossier annulé (CLIENT)';
      case 'EK_PHYSICAL_FOLDER_DELIVERED':
        return 'Dossier physique livré';
      case 'EK_BANK_RESERVE':
        return 'Dossier réserve banque';
      case 'CENTRAL_RISK_CONSULTATION':
        return 'Consultation de la Centrale des Risques';
      case 'CLIENT_CENTRAL_RISK_NOTIFICATION':
        return 'Notification Client Centrale des risques';
      case 'BANK_NOTIFICATION':
        return 'Notification banque (Attente accord)';
      case 'BANK_PAYMENT_CONFIRMATION':
        return 'Confirmation du paiement banque';
      case 'EK_CONTROL_BEFORE_GUARANTEES_PRINTING':
        return 'Control avant impression des garanties Ekiclik';
      case 'EK_FOLDER_REFUSED':
        return 'Dossier Rejeté Ekiclik';
      case 'EK_BANK_REFUSED':
        return 'Dossier Rejeté Banque';
      case 'EK_ORDER_CREATED':
        return 'Commande créée';
      case 'EK_ORDER_PENDING':
        return 'Commande en attente';
      case 'EK_ORDER_IN_PREPARATION':
        return 'Commande en préparation';
      case 'EK_ORDER_IN_DELIVERY':
        return 'Livraison en cours';
      case 'EK_ORDER_DELIVERED':
        return 'Client livré';
      case 'EK_CLIENT_ORDER_CANCELED':
        return 'Commande annulée';
      case 'FINANCIAL_FOLDER':
        return 'Dossiers financés';
      case 'EK_BANK_GUARANTEES_SIGNED_IN_PROGRESS':
        return 'Garanties signés en cours validation Banque';
      default:
        return folderState;
    }
  }
}
